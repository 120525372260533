import { Avatar, Box, Button, TextField, Typography, Icon } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  authActions
} from '../../actions'

const Login = (props) => {
  const dispatch = useDispatch()

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)

    dispatch(authActions.loginPost({
      username: data.get('username'),
      password: data.get('password'),
      otp: data.get('otp'),
    }))
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <Icon>lock</Icon>
      </Avatar>
      <Typography component='h1' variant='h5'>
        Achille© Admin
      </Typography>
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mx: 2 }}>
        <TextField
          margin='normal'
          required
          fullWidth
          id='username'
          label='Username'
          name='username'
          autoComplete='username'
          autoFocus
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='current-password'
          autoComplete='current-password'
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='otp'
          label='OTP'
          type='number'
          id='one-time-code'
          autoComplete='one-time-code'
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Accedi
        </Button>
      </Box>
    </Box>
  )
}

export default Login