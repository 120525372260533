import store from '../stores'
import { ajaxCall, showErrorMessageFromRes } from '../utils'
import { messageActions, socketActions } from '../actions'
import moment from 'moment'

export const commonActions = {
  callPost: (params, data, url, { download, socket } = {}) => {
    ajaxCall({
      config: {
        url,
        method: 'post',
        params,
        data,
        responseType: download ? 'blob' : null
      },
      success: (res) => {
        if (socket) {
          store.dispatch(socketActions.socketConf(res.data))

          return
        }

        if (download) {
          const blob = new Blob([res.data])

          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(blob)

          link.download = 'dbs_' + moment().format('YYYYMMDD-HHMMSS') + '.zip'

          link.click()

          return
        }

        store.dispatch(messageActions.showMessage({
          message: (res.data || { message: '🎉 Operazione completata con successo'}).message,
          type: res.data.status,
          autoHide: true
        }))

        if ((res.data || {}).reload) {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      },
      error: (res) => {
        showErrorMessageFromRes(res)
      },
    })

    return ({
      type: 'common/callPost'
    })
  }
}