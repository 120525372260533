import _ from 'lodash'

const initialState = {}

const dashboardReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'dashboard/get':
      const newState = _.cloneDeep(state)

      newState.items = action.data.data

      return newState
    default:
      return state
  }
}

export default dashboardReducer