import _ from 'lodash'
import store from '../stores'
import { messageActions } from '../actions'

const initialState = {
  data: null,
  goTo: null,
}

const actionFormReducer = (
  state = initialState,
  action
) => {
  let newState

  switch (action.type) {
    case 'actionForm/get':
      newState = {
        ..._.cloneDeep(state),
        exitForm: false,
        data: action.data ? action.data.data : null,
        goTo: null
      }

      if (newState.data && newState.data.items) {
        newState.data.items.forEach((field) => {
          if (field.values) {
            field.values.forEach((fieldValue => {
              return fieldValue.id = _.uniqueId('field_id')
            }))
          }
        })
      } else {
        newState.data = null
      }

      return newState
    case 'actionForm/post':
      newState = {
        ..._.cloneDeep(state),
        sending: true
      }

      return newState
    case 'actionForm/done':
      newState = {
        ..._.cloneDeep(state),
        sending: false,
        goTo: action.data.goTo || null,
        socketConf: action.data.socketConf
      }

      setTimeout(() => {
        return store.dispatch(messageActions.showMessage({
          message: action.data.message,
          type: 'success',
          autoHide: true
        }))
      }, 200)

      return newState
    case 'actionForm/addValue':
      newState = _.cloneDeep(state)

      if (newState.data.items[action.fieldIndex].repeatable) {
        _.set(newState.data.items[action.fieldIndex], 'values.' + action.fieldName, action.value)
      } else {
        newState.data.items[action.fieldIndex].value = action.value
      }

      return newState
    case 'actionForm/removeValue':
      newState = _.cloneDeep(state)

      if (newState.data.items[action.fieldIndex].repeatable) {
        _.unset(newState.data.items[action.fieldIndex].values, action.fieldName)
      } else {
        delete newState.data.items[action.fieldIndex].value
      }

      return newState
    case 'actionForm/navigate':
      newState = _.cloneDeep(state)

      newState.goTo = null

      return newState
    case 'actionForm/cleanSocketConfig':
      newState = {
        ..._.cloneDeep(state),
        socketConf: null
      }

      return newState
    default:
      return state
  }
}

export default actionFormReducer