/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Paper from '@mui/material/Paper'
import shadows from '@mui/system/shadows'
import Typography from '@mui/material/Typography'
import { Alert, Box } from '@mui/material'
import { minHeight } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import socketIOClient from 'socket.io-client'
import _ from 'lodash'

import {
  socketActions
} from '../../actions'

const rootStyle = css({
  padding: '15px',
  margin: '15px'
})

const SocketContainer = (props) => {
  const dispatch = useDispatch()

  const match = useParams()

  const [logs, setLogs] = useState([])
  const [error, setError] = useState(null)
  const [closed, setClosed] = useState(null)
  const [currentSocket, setCurrentSocket] = useState(null)
  const [newLogs, setNewLogs] = useState([])
  const [scrolled, setScrolled] = useState(false)

  const logsListRef = useRef(null)
  const logsDataRef = useRef(null)

  useEffect(() => {
    const socket = socketIOClient()

    setCurrentSocket(socket)

    dispatch(socketActions.socketGet(props.pageType, match))
  }, [])

  useEffect(() => {
    return () => {
      if (currentSocket) {
        currentSocket.disconnect()

        currentSocket.close()
      }
    }
  }, [currentSocket])

  const { data } = useSelector(state => state.socketReducer)

  const { title, socketConf } = data || {}

  useEffect(() => {
    if (socketConf && currentSocket) {
      if (socketConf.data) {
        currentSocket.on(socketConf.data, data => {
          setLogs(previousLogs => {
            return [...previousLogs, data]
          })
        })
      }

      if (socketConf.error) {
        currentSocket.on(socketConf.error, error => {
          setError(error)
        })
      }

      if (socketConf.close) {
        currentSocket.on(socketConf.close, code => {
          console.log(code)

          setClosed(code || '-')
        })
      }
    }
  }, [socketConf])

  useEffect(() => {
    if (!scrolled) {
      if (logs && logs.length) {
        setNewLogs(previousLogs => {
          return [...previousLogs, logs]
        })

        setLogs([])
      }

      if (logsListRef && logsListRef.current && logsDataRef && logsDataRef.current) {
        const offsetBottom = logsDataRef.current.offsetTop + logsDataRef.current.offsetHeight

        logsListRef.current.scrollTo({ top: offsetBottom })
      }
    }
  }, [logs, scrolled])

  if (!data) {
    return 'Missing data'
  }

  if (!socketConf) {
    return (
      <Box m={2}>
        <Alert severity='error'>Configurazione socket mancante</Alert>
      </Box>
    )
  }

  const handleScroll = event => {
    if (event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight) {
      if (scrolled) {
        setScrolled(false)
      }
    } else {
      setScrolled(true)
    }
  }

  return (
    <Paper
      css={rootStyle}
      elevation={3}
      p={2}
    >
      { title ? (
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      ) : ''}

      {error || closed ? (
        <Box my={2}>
          { error ? (
            <Alert severity='error'>
              <b>Errore</b>: <i>{error}</i> <br></br>
              Prova a riavviare la pagina!
            </Alert>
          ) : ''}

          { closed ? (
            <Alert severity='info'>
              Operazione conclusa. {closed !== '-' ? ( '[' + closed + ']') : ''}
            </Alert>
          ) : ''}
        </Box>
      ) : ''}

      <Box
        p={2}
        sx={{
          backgroundColor: 'black',
          borderRadius: '10px',
          minHeight: '500px',
          maxHeight: '500px',
          overflow: 'hidden',
          overflowY: 'scroll'
        }}
        ref={logsListRef}
        onScroll={_.throttle(handleScroll, 100)}
      >
        <Typography
          variant='body'
          sx={{
            color: 'white',
            whiteSpace: 'pre-wrap'
          }}
          ref={logsDataRef}
          dangerouslySetInnerHTML={{ __html: newLogs.join('<br>')}}
        />
      </Box>
    </Paper>
  )
}

export default SocketContainer