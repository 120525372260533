export const confirmActions = {
  handleOpen: (config, callback, callbackParams) => ({
    type: 'confirm/handle-open',
    config,
    callback,
    callbackParams
  }),
  handleConfirm: () => ({
    type: 'confirm/handle-confirm'
  }),
  handleClose: () => ({
    type: 'confirm/handle-close',
  })
}
