import _ from 'lodash'
import store from '../stores'
import { messageActions } from '../actions'

const initialState = {
  data: null,
}

const socketReducer = (
  state = initialState,
  action
) => {
  let newState

  switch (action.type) {
    case 'socket/get':
      newState = _.cloneDeep(state)

      newState.data = action.data ? action.data.data : null

      return newState
    case 'socket/conf':
      newState = {
        ..._.cloneDeep(state),
        socketConf: action.data.socketConf
      }

      setTimeout(() => {
        return store.dispatch(messageActions.showMessage({
          message: action.data.message,
          type: 'success',
        }))
      }, 200)

      return newState
    case 'socket/cleanConf':
      newState = {
        ..._.cloneDeep(state),
        socketConf: null
      }

      return newState
    default:
      return state
  }
}

export default socketReducer