import ActionForm from '../containers/ActionForm'
import Dashboard from '../containers/Dashboard'
import SocketContainer from '../containers/Socket'

export const indexRoutes = (appConfig) => {
  return [
    {
      paths: [
        '/',
        appConfig.appPrefix,
      ],
      component: Dashboard,
      props: {
        pageType: 'dashboard'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/restart',
        appConfig.appPrefix + '/instance/restart/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'instanceRestart'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/create',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'instanceCreate'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/update',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'instanceUpdate'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/view/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: SocketContainer,
      props: {
        pageType: 'instanceView'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/db',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: Dashboard,
      props: {
        pageType: 'db'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/db/edit',
        appConfig.appPrefix + '/db/edit/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        },
        {
          label: 'Databases',
          value: appConfig.appPrefix + '/db'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'db'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/server',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: Dashboard,
      props: {
        pageType: 'server'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/server/edit',
        appConfig.appPrefix + '/server/edit/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        },
        {
          label: 'Servers',
          value: appConfig.appPrefix + '/server'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'server'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/move',
        appConfig.appPrefix + '/instance/move/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'instanceMove'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/instance/delete',
        appConfig.appPrefix + '/instance/delete/:id',
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          value: appConfig.appPrefix + '/'
        }
      ],
      component: ActionForm,
      props: {
        pageType: 'instanceDelete'
      }
    }
  ]
}