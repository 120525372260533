export const appActions = {
  initApp: () => ({
    type: 'app/init'
  }),
  startLoading: () => ({
    type: 'app/loading/start'
  }),
  stopLoading: () => ({
    type: 'app/loading/stop'
  })
}