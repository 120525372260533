import TableSelectFilter from './TableSelectFilter'
import TableTextFilter from './TableTextFilter'

import { TYPES } from '../../../utils/components'

const filters = {
  [TYPES.FORM.text]: TableTextFilter,
  [TYPES.FORM.select]: TableSelectFilter
}

const TableFilter = (props) => {
  const { element, onChange } = props

  if (!filters[element.filter.type]) {
    return ''
  }

  const NewFilter = filters[element.filter.type]

  return (
    <NewFilter element={element} onChange={onChange} />
  )
}

export default TableFilter