import {
  Autocomplete,
  TextField
} from '@mui/material'
import { useState } from 'react'

import { TYPES } from '../../utils/components'

const SelectField = (props) => {
  const { item, onChange, index } = props

  if (item.options && item.value) {
    item.value = item.options.find(el => el.value === item.value.value || el.value == item.value)
  }

  const { label, helper, required, isClearable, options, value } = item

  const [ selectedValue, setSelectedValue ] = useState(value || '')

  return (
    <Autocomplete
      id={'field_' + item.name}
      size='small'
      value={selectedValue}
      onChange={onChange ? (event, value) => {
        setSelectedValue(value)

        return onChange({type: TYPES.FORM.select}, item, value, index)
      } : null }
      getOptionLabel={option => option.label || ''}
      options={options || []}
      disableClearable={!isClearable}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) =>
        <TextField {...params} label={label} helperText={helper} required={required} />
      }
    />
  )
}

export default SelectField