import { combineReducers } from 'redux'
import actionFormReducer from './ActionForm'
import appReducer from './App'
import authReducer from './Auth'
import confirmReducer from './Confirm'
import dashboardReducer from './Dashboard'
import messageReducer from './Message'
import socketReducer from './Socket'

const reducers = combineReducers({
  actionFormReducer,
  appReducer,
  authReducer,
  confirmReducer,
  dashboardReducer,
  messageReducer,
  socketReducer
})

export default reducers