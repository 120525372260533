import store from '../stores'
import { ajaxCall, showErrorMessageFromRes } from '../utils'
import _ from 'lodash'

const { appConfig } = store.getState().appReducer

export const actionFormActions = {
  actionFormGet: (pageType, params) => {
    store.dispatch(actionFormActions.actionForm())

    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'Get'].path,
        params
      },
      success: (res) => {
        store.dispatch(actionFormActions.actionForm(res.data, pageType))
      },
      error: (res) => {
        console.log(res)
      },
    })

    return ({
      type: 'actionForm/getData',
      pageType
    })
  },
  actionForm: (data, pageType) => ({
    data,
    pageType,
    type: 'actionForm/get'
  }),
  actionFormPost: (pageType, params, url) => {
    const { data } = store.getState().actionFormReducer

    const newFormContent = _.cloneDeep(data)

    const allowedProps = ['value', 'values', 'name', 'required']

    newFormContent.items.forEach(field => {
      if (field.items) {
        return field.items.forEach(innerField => {
          return Object.keys(innerField).forEach(propName => {
            if (allowedProps.indexOf(propName) === -1) {
              delete innerField[propName]
            }
          })
        })
      }

      return Object.entries(field).forEach(([propName]) => {
        if (allowedProps.indexOf(propName) === -1) {
          delete field[propName]
        }
      })
    })

    ajaxCall({
      config: {
        url: url,
        method: 'post',
        data: newFormContent,
        params: params
      },
      success: (res) => {
        store.dispatch(actionFormActions.actionFormDone(res.data))
      },
      error: (res) => {
        showErrorMessageFromRes(res)
      },
    })

    return ({
      type: 'actionForm/post'
    })
  },
  actionFormDone: (data) => ({
    type: 'actionForm/done',
    data,
  }),
  actionFormError: (data) => ({
    type: 'actionForm/error',
    data,
  }),
  actionFormAddValue: (value, fieldName, fieldIndex) => ({
    type: 'actionForm/addValue',
    value,
    fieldName,
    fieldIndex,
  }),
  actionFormRemoveValue: (fieldName, fieldIndex) => ({
    type: 'actionForm/removeValue',
    fieldName,
    fieldIndex,
  }),
  actionFormNavigate: () => ({
    type: 'actionForm/navigate',
  }),
  actionFormCleanSocketConfig: () => ({
    type: 'actionForm/cleanSocketConfig',
  })
}