import { HashRouter, Route, Routes } from 'react-router-dom'
import Notfound from '../containers/NotFound'
import { indexRoutes } from './Config'
import { useSelector } from 'react-redux'
import BreadcrumbsComponent from '../components/Breadcrumbs'
import { css } from '@emotion/react'
import { Container } from '@mui/material'

const routContainerStyle = css({
  overflowY: 'auto',
  flexGrow: 1,
})

const ACRoutes = (props) => {
  const { appConfig } = useSelector(state => state.appReducer)

  const routes = indexRoutes(appConfig)

  return (
    <HashRouter>
      <BreadcrumbsComponent />
      <Container css={routContainerStyle} maxWidth='xl'>
        <Routes>
          {routes.map((route, routeIndex) => {
            return route.paths.map((path, pathIndex) => {
              const TmpComponent = route.component

              return (
                <Route path={path} key={routeIndex + '-' + pathIndex} element={<TmpComponent {...route.props} />} />
              )
            })
          })}
          <Route path='*' element={<Notfound/>} />
        </Routes>
      </Container>
    </HashRouter>
  )
}

export default ACRoutes