import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  confirmActions
} from '../../actions'

const Confirm = (props) => {
  const { openConfirm, confirmConfig } = useSelector(state => state.confirmReducer)

  if (!confirmConfig) {
    return ''
  }

  const dispatch = useDispatch()

  return (
    <Dialog
      open={openConfirm}
      onClose={props.onHandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {confirmConfig.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmConfig.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(confirmActions.handleClose())}>
          {confirmConfig.ko || 'Annulla'}
        </Button>
        <Button onClick={() => dispatch(confirmActions.handleConfirm())} autoFocus>
          {confirmConfig.ok || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Confirm
