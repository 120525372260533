import { TextField } from '@mui/material'
import { useRef, useState } from 'react'
import { TYPES } from '../../utils/components'

const base64 = (file) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    if (!file) {
      return resolve({
        base64: null
      })
    }

    reader.readAsDataURL(file)

    reader.onload = function () {
      resolve({
        base64: reader.result,
        filename: file.name,
        type: file.type,
        isNew: true,
      })
    }

    reader.onerror = function (error) {
      reject(error)
    }
  })
}

const FileUploadComponent = (props) => {
  const { item, index, onChange } = props

  const { label, helper, required, accept = ['*'] } = item

  const [ value, setValue ] = useState('')

  const inputRef = useRef()

  return (
    <TextField
      id={'field_' + item.name}
      label={label}
      focused
      color='secondary'
      type='file'
      variant='outlined'
      fullWidth
      helperText={helper}
      required={required}
      inputProps={{
        accept: accept.join(','),
        ref: {inputRef},
      }}
      onChange={(event) => {
        const val = event.target.files[0]

        return base64(val).then(file => {
          onChange({type: TYPES.FORM.file}, item, file, index)

          setValue(file)
        }).catch(error => {
          console.log(error)
        })
      }}
      sx={{
        '& label.Mui-focused': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(196, 196, 196, 1)',
            border: '1px solid rgba(196, 196, 196, 1)'
          },
        },
      }}
    />
  )
}

export default FileUploadComponent