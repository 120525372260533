import ButtonComponent from './Button'
import GridComponent from './Grid'
import FileUploadComponent from './FormField/FileUploader'
import Message from './Message'
import PaperComponent from './Paper'
import SelectComponent from './FormField/Select'
import TableComponent from './Table'
import TextFieldComponent from './FormField/TextField'
import TitleBlock from './TitleBlock'
import CheckboxComponent from './FormField/Checkbox'
import { TYPES } from '../utils/components'

const getTypes = () => {
  return {
    [TYPES.button]: ButtonComponent,
    [TYPES.FORM.select]: SelectComponent,
    [TYPES.FORM.text]: TextFieldComponent,
    [TYPES.FORM.file]: FileUploadComponent,
    [TYPES.FORM.checkbox]: CheckboxComponent,
    [TYPES.grid]: GridComponent,
    [TYPES.message]: Message,
    [TYPES.paper]: PaperComponent,
    [TYPES.table]: TableComponent,
    [TYPES.title]: TitleBlock,
  }
}

export default getTypes