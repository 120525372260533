/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button, Link, Breadcrumbs, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { indexRoutes } from '../../routes/Config'

const rootStyle = css({
  padding: '15px',
  width: '100%'
})

const BreadcrumbsComponent = (props) => {
  const { appConfig } = useSelector (state => state.appReducer)

  const routes = indexRoutes(appConfig)

  const rootConfig = routes.find(route => {
    return !!route.paths.find(path => {
      const pattern = new RegExp(path.replace(/:([a-zA-Z]+(\/|$))/ig, '(.*?)') + '$', 'ig')

      return pattern.test(location.hash.replace('#/', ''))
    })
  })

  if (!rootConfig || !rootConfig.breadcrumbs) {
    return ''
  }

  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      css={rootStyle}>
      {rootConfig.breadcrumbs.map((breadcrumb, index) =>
        <Link
          underline="hover"
          key={index}
          color="inherit"
          to={breadcrumb.value}
          component={NavLink}
        >
          {breadcrumb.label}
        </Link>
      )}
      <Typography key="3" color="text.primary">
        Corrente
      </Typography>
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent