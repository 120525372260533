import store from '../stores'

import {
  appActions
} from '../actions'
import jsCookie from 'js-cookie'

const initialState = {}

const authReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'auth/login':
      if (!jsCookie.get('user_sid') || !sessionStorage.getItem('s_user_sid')) {
        sessionStorage.setItem('s_user_sid', '*******************')
      }

      setTimeout(() => {
        return store.dispatch(appActions.initApp())
      }, 100)

      return state
    case 'auth/logout':
      if (jsCookie.get('user_sid')) {
        jsCookie.remove('user_sid')
      }

      sessionStorage.removeItem('s_user_sid')

      window.location.href = '/'

      setTimeout(() => {
        return store.dispatch(appActions.initApp())
      }, 100)

      return state
    default:
      return state
  }
}

export default authReducer
