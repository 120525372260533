import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
} from '@mui/material'
import { green, amber, red } from '@mui/material/colors'

import {
  messageActions
} from '../../actions'

function TransitionRight(props) {
  return <Slide {...props} direction="right" />
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />
}

const Message = (props) => {
  const { openMessage, messageConfig } = useSelector(state => state.messageReducer)

  if (!messageConfig) {
    return ''
  }

  const dispatch = useDispatch()

  const autoHideDuration = messageConfig.autoHide ? 5000 : null

  switch (messageConfig.messageType) {
    case 'modal':
      return (
        <Dialog
          open={openMessage}
          TransitionComponent={TransitionUp}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description' >
          <DialogTitle id='alert-dialog-slide-title'>
            {messageConfig.title || 'Info'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description' dangerouslySetInnerHTML={{__html: messageConfig.message}}>
            </DialogContentText>
          </DialogContent>
          {messageConfig.confirm ? (
            <DialogActions>
              <Button onClick={() => dispatch(messageActions.closeMessage())} color="primary" autoFocus>
                {messageConfig.confirm}
              </Button>
            </DialogActions>
          ) : ''}

        </Dialog>
      )
    default:
      return (
        <Snackbar
          open={openMessage}
          onClose={() => dispatch(messageActions.closeMessage())}
          autoHideDuration={autoHideDuration}
          TransitionComponent={TransitionRight}
          sx={{zIndex: 100000}}>
          <Alert
            onClose={() => dispatch(messageActions.closeMessage())}
            severity={messageConfig.type}
            sx={{
              width: '100%',
              boxShadow: 8,
            }}>
            {messageConfig.message}
          </Alert>
        </Snackbar>
      )
  }
}

export default Message
