
import socketIOClient from 'socket.io-client'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider} from '@mui/material'
import { teal } from '@mui/material/colors'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Message from '../../components/Message'
import Confirm from '../../components/Confirm'
import Loading from '../../components/Loading'
import Login from '../Login'
import ACRoutes from '../../routes'

import { appActions, socketActions, messageActions } from '../../actions'
import { lowerFirst } from 'lodash'
import { commonActions } from '../../actions/Common'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0069c0',
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: '#f9fafc'
    }
  },
  shape: {
    borderRadius: 10,
  }
})

const containerStyle = css({
  maxWidth: '100%',
  minWidth: '100%',
  maxHeight: '100vh',
  minHeight: '100vh',
})

const appAndFooterContainerStyle = css({
  paddingTop: 60,
  maxHeight: '100vh',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column'
})

const routerContainerStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const App = (props) => {
  const { appConfig } = useSelector(state => state.appReducer)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.initApp())

    const socket = socketIOClient()

    setCurrentSocket(socket)
  }, [])

  const [currentSocket, setCurrentSocket] = useState(null)

  const closeSocket = () => {
    if (currentSocket) {
      currentSocket.disconnect()

      currentSocket.close()

      dispatch(socketActions.socketCleanConf())
    }
  }

  const { socketConf } = useSelector(state => state.socketReducer)

  useEffect(() => {
    if (socketConf && currentSocket) {
      dispatch(appActions.startLoading())

      if (socketConf.error) {
        currentSocket.on(socketConf.error, error => {
          console.log(error)

          dispatch(appActions.stopLoading())

          dispatch(socketActions.socketCleanConf())

          dispatch(messageActions.showMessage({
            message: error,
            type: 'error',
            autoHide: true
          }))
        })
      }

      if (socketConf.message) {
        currentSocket.on(socketConf.message, data => {
          dispatch(messageActions.showMessage({
            message: data.message,
            type: data.type || 'info',
            messageId: 'down'
          }))
        })
      }

      if (socketConf.close) {
        currentSocket.on(socketConf.close, data => {
          console.log(data)

          closeSocket()

          const socket = socketIOClient()

          setCurrentSocket(socket)

          dispatch(appActions.stopLoading())

          if (data.path) {
            dispatch(commonActions.callPost({}, { path: data.path }, ['', 'api', 'file', 'download'].join('/'), { download: true }))
          }

          dispatch(messageActions.showMessage({
            message: data.message,
            type: 'success',
            autoHide: true
          }))
        })
      }
    }
  }, [socketConf])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Confirm />
      <Message />
      <Loading />
      {(!appConfig || !appConfig.isLogged) ? (
        <Login />
      ) : (
        <div css={containerStyle}>
          <Header />
          <div css={appAndFooterContainerStyle}>
            <div css={routerContainerStyle}>
              <ACRoutes />
            </div>
            <Footer />
          </div>
        </div>
      )}
    </ThemeProvider>
  )
}

export default App
