import { Typography } from '@mui/material'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const heroContentStyle = css({
  maxWidth: 600,
  margin: '0 auto',
  padding: '16px 0 12px',
})

const Error = (props) => {
  const { error } = props

  return (
    <div css={heroContentStyle}>
      <Typography component='h1' variant='h2' align='center' gutterBottom>
        {error.code}
      </Typography>
      <Typography component='h3' variant='h5' align='center' gutterBottom>
        {error.message}
      </Typography>
    </div>
  )
}

export default Error