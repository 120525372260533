import { Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react'
import { TYPES } from '../../utils/components'

const CheckboxComponent = (props) => {
  const { item, index, onChange } = props

  const { label } = item

  const [ value, setValue ] = useState(item.value || '')

  return (
    <FormControlLabel control={
      <Checkbox
        id={'field_' + item.name}
        label={label}
        checked={!!value}
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={(event) => {
          const value = event.target.checked

          onChange({type: TYPES.FORM.checkbox}, item, value, index)

          setValue(value)
        }}
      />
    } label={label} />
  )
}

export default CheckboxComponent