import axios from 'axios'
import store from '../stores'
import jsCookie from 'js-cookie'

import {
  appActions,
  messageActions
} from '../actions'

export const ajaxCall = (obj) => {
  obj.config.headers = {
    Accept: 'application/json',
  }

  const isMobile = window.innerWidth <= 800

  obj.config.timeout = 300000

  obj.config.params = {
    ...obj.config.params,
    v: new Date().getTime(),
    isMobile
  }

  store.dispatch(appActions.startLoading())

  axios(obj.config).then(res => {
    store.dispatch(appActions.stopLoading())

    return obj.success(res)
  }).catch(error => {
    const { data = {} } = error.response || {}

    if (data.authError) {
      if (jsCookie.get('user_sid')) {
        jsCookie.remove('user_sid')
      }

      sessionStorage.removeItem('s_user_sid')
    }

    store.dispatch(appActions.stopLoading())

    return obj.error(error.response || error)
  })
}

export const showErrorMessageFromRes = (res) => {
  let errorMessage = {
    message: '[' + (res.status || '500') + '] ' + ((res.data || { message: 'Errore'}).message || res.statusText),
    type: 'error',
    autoHide: true
  }

  setTimeout(() => {
    return store.dispatch(messageActions.showMessage(errorMessage))
  }, 200)
}