import { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import TableActions from './TableActions'
import TableFilter from './Filters/TableFilter'

const tableCellTitleStyle = css({
  maxWidth: 200,
  width: 200
})

const desc = (a, b, orderBy) => {
  const tmpA = a[orderBy + '_sort'] || a[orderBy]

  const tmpB = b[orderBy + '_sort'] || b[orderBy]

  let newParam = (typeof tmpA === 'string' ? tmpA.toUpperCase() : tmpA) || ''

  let oldParam = (typeof tmpB === 'string' ? tmpB.toUpperCase() : tmpB) || ''

  if (oldParam < newParam) {
    return -1
  }

  if (oldParam > newParam) {
    return 1
  }

  return 0
}

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const stableSort = (array, sortingMethod) => {
  const stabilizedThis = array.map((element, index) => [element, index])

  stabilizedThis.sort((a, b) => {
    const order = sortingMethod(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

const TableComponent = (props) => {
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ order, setOrder ] = useState('asc')
  const [ orderBy, setOrderBy ] = useState()
  const [ filters, setFilters ] = useState({})

  const { item, classes } = props

  const { actions, columns, rows } = item

  if (!columns || !rows) {
    return ''
  }

  const handleChangePage = (event, page) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))

    setPage(0)
  }

  const stableFilter = (data) => {
    let newData = _.cloneDeep(data)

    Object.entries(filters).forEach(filterElement => {
      const filter = filterElement[0]

      const content = filterElement[1]

      return newData = newData.filter(element => {
        switch (content.type) {
          case 'select':
            return element[filter] === content.value
          default:
            const regex = RegExp('(' + content.value + ')', 'ig')

            return regex.test(element[filter])
        }
      })
    })

    return newData
  }

  const onFilterChange = (element, value) => {
    const newFilters = _.cloneDeep(filters)

    if (value === null) {
      if (filters[element.field]) {
        delete newFilters[element.field]
      }

      setPage(0)

      setFilters(newFilters)

      return
    }

    newFilters[element.field] = {
      value: _.isObject(value) ? value.value : value,
      type: element.filter.type
    }

    setPage(0)

    setFilters(newFilters)
  }

  const createSortHandler = (field) => {
    let newOrder = (field === orderBy) ? (order === 'asc') ? 'desc' : 'asc' : order

    setOrderBy(field)

    setOrder(newOrder)

    handleChangePage(null, 0)
  }

  const getPageData = (filteredData) => {
    return stableSort(filteredData, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }

  const filteredData = stableFilter(rows)

  const data = getPageData(filteredData)

  const haveFilters = columns.filter(element => {
    return !!element.filter
  }).length

  return (
    <div>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  css={tableCellTitleStyle}
                  key={column.field}
                >
                  <TableSortLabel
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : 'asc'}
                    onClick={() => createSortHandler(column.field)}
                  >
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                      {column.text}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              {actions && actions.length ? (
                <TableCell css={tableCellTitleStyle}/>
              ) : null}
            </TableRow>
            {haveFilters ? (
              <TableRow>
                {columns.map((column, index) => {
                  const filter = column.filter

                  return (
                    <TableCell key={index}>
                      {filter ? (
                        <TableFilter element={column} onChange={onFilterChange} />
                      ) : null }
                    </TableCell>
                  )
                })}
                {actions && actions.length ? (
                  <TableCell padding='checkbox'></TableCell>
                ) : null}
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {data.map(row => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {columns.map(column => {
                    const value = row[column.field]

                    return (
                      <TableCell key={column.field}>
                        {(typeof value === 'boolean') ? (
                          <Icon sx={{ color: !value ? 'red' : 'green' }} variant='outlined'>{!value ? 'remove_circle' : 'check_circle_outline'}</Icon>
                        ) : (
                          value
                        )}
                      </TableCell>
                    )
                  })}
                  {actions && actions.length ? (
                    <TableCell>
                      <TableActions actions={actions} item={row} />
                    </TableCell>
                  ) : null}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Elementi per pagina'
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default TableComponent