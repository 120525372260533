import _ from 'lodash'

const initialState = {
  openConfirm: false,
  confirmConfig: {},
  callback: null
}

const confirmReducer = (
  state = initialState,
  action
) => {
  let newState = null

  switch (action.type) {
    case 'confirm/handle-open':
      newState = {
        ..._.cloneDeep(state),
        openConfirm: true,
        confirmConfig: action.config,
        callback: action.callback,
      }

      return newState
    case 'confirm/handle-close':
      newState = {
        ...initialState,
      }

      return newState
    case 'confirm/handle-confirm':
      newState = {
        ...initialState,
      }

      setTimeout(() => {
        state.callback()
      }, 10)

      return newState
    default:
      return state
  }
}

export default confirmReducer
