import store from '../stores'
import { ajaxCall, showErrorMessageFromRes } from '../utils'

const { appConfig } = store.getState().appReducer

export const dashboardActions = {
  dashboardGet: (pageType, params) => {
    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'sGet'].path,
        params
      },
      success: (res) => {
        store.dispatch(dashboardActions.dashboard(res.data, pageType))
      },
      error: (res) => {
        showErrorMessageFromRes(res)
      },
    })

    return ({
      type: 'dashboard/getData',
      pageType
    })
  },
  dashboard: (data, pageType) => ({
    data,
    pageType,
    type: 'dashboard/get'
  })
}