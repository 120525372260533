import { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TextField } from '@mui/material'

const rootStyle = css({
  margin: 0,
})

const TableTextFilter = (props) =>  {
  const { element, onChange } = props

  const { placeholder } = element.filter

  const [ value, setValue ] = useState('')

  let changeTimeout = null

  return (
    <TextField
      placeholder={placeholder}
      css={rootStyle}
      fullWidth
      variant='outlined'
      size='small'
      value={value}
      onChange={(event) => {
        const val = event.target.value

        if (changeTimeout) {
          clearTimeout(changeTimeout)
        }

        changeTimeout = setTimeout(element => {
          const { value: newValue, element: newElement } = element

          onChange(newElement, newValue)
        }, 100, {value: val, element})

        setValue(val)
      }} />
  )
}

export default TableTextFilter