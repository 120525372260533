/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const rootStyle = css({
  display: 'flex',
  alignItems: 'center',
  minHeight: '50px',
  maxHeight: '50px',
  width: '100%',
  padding: '0 1rem',
  fontSize: '.75rem',
  borderTop: '1px solid #ddd',
  backgroundColor: 'white',
})

const Footer = (props) => {
  const { appConfig } = useSelector (state => state.appReducer)

  return (
    <footer css={rootStyle}>
      {dayjs().format('YYYY')} &copy; ACHILLE ADMIN by
      <Button
        size='small'
        href='https://www.medialis.tech'
        title='Medialis.tech'
        target='_blank'
        rel='noopener noreferrer'
        sx={{
          fontWeight: 'bold',
          textTransform: 'none'
        }}
      >
        Medialis.tech
      </Button>
    </footer>
  )
}

export default Footer