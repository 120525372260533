import Error from '../Error'

const Notfound = (props) => {
  return (
    <Error error={{
      code: 404,
      message: 'Pagina non trovata'
    }}/>
  )
}

export default Notfound