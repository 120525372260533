import { TextField } from '@mui/material'
import { useState } from 'react'
import { TYPES } from '../../utils/components'

const TextFieldComponent = (props) => {
  const { item, index, onChange } = props

  const { label, placeholder, helper, required } = item

  const [ value, setValue ] = useState(item.value || '')

  return (
    <TextField
      id={'field_' + item.name}
      label={label}
      placeholder={placeholder}
      helperText={helper}
      required={required}
      fullWidth
      variant='outlined'
      size='small'
      value={value}
      onChange={(event) => {
        const value = event.target.value

        onChange({type: TYPES.FORM.text}, item, value, index)

        setValue(value)
      }}
    />
  )
}

export default TextFieldComponent