import store from '../stores'
import { ajaxCall, showErrorMessageFromRes } from '../utils'
import { messageActions } from '../actions'

const { appConfig } = store.getState().appReducer

export const socketActions = {
  socketGet: (pageType, params) => {
    store.dispatch(socketActions.socket())

    ajaxCall({
      config: {
        url: appConfig.actions[pageType][pageType + 'Get'].path,
        params
      },
      success: (res) => {
        store.dispatch(socketActions.socket(res.data, pageType))
      },
      error: (res) => {
        showErrorMessageFromRes(res)
      },
    })

    return ({
      type: 'socket/getData',
      pageType
    })
  },
  socket: (data, pageType) => ({
    data,
    pageType,
    type: 'socket/get'
  }),
  socketConf: (data) => ({
    type: 'socket/conf',
    data,
  }),
  socketCleanConf: () => ({
    type: 'socket/cleanConf',
  })
}