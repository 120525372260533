import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography, Icon } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '../../actions'

const Header = (props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()

    dispatch(authActions.logout())
  }

  return (
    <AppBar position='fixed' elevation={1} color='background'>
      <Toolbar>
        <Typography variant='h6' sx={{ flexGrow: 1 }}>
          Achille© Admin
        </Typography>
        <div>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'
          >
            <Icon>account_circle</Icon>
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Esci</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
