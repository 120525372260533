import jsCookie from 'js-cookie'
import store from '../stores'
import { ajaxCall, showErrorMessageFromRes } from '../utils'

const { appConfig } = store.getState().appReducer

export const authActions = {
  loginPost: (data) => {
    if (jsCookie.get('user_sid') && sessionStorage.getItem('s_user_sid')) {
      store.dispatch(authActions.login())
    } else {
      if (jsCookie.get('user_sid')) {
        jsCookie.remove('user_sid')
      }

      ajaxCall({
        config: {
          url: appConfig.actions.auth.loginPost.path,
          method: 'post',
          data: data
        },
        success: (res) => {
          store.dispatch(authActions.login(res.data))
        },
        error: (res) => {
          if (jsCookie.get('user_sid')) {
            jsCookie.remove('user_sid')
          }

          showErrorMessageFromRes(res)
        },
      })
    }

    return ({
      type: 'auth/login/post'
    })
  },
  login: (res) => ({
    type: 'auth/login',
    res: res
  }),
  logout: () => {
    ajaxCall({
      config: {
        url: appConfig.actions.auth.logoutPost.path,
        method: 'post'
      },
      success: (res) => {
        store.dispatch(authActions.logoutAfterPost(res.data))
      },
      error: (res) => {
        showErrorMessageFromRes(res)
      },
    })

    return {
      type: 'auth/logout/post'
    }
  },
  logoutAfterPost: () => ({
    type: 'auth/logout'
  })
}
