import jsCookie from 'js-cookie'
import _ from 'lodash'

const initialState = {
  appConfig: window.appConfig || {}
}

let newState = null

const appReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'app/init':
      newState = _.cloneDeep(state)

      delete newState.appConfig.isLogged

      if (jsCookie.get('user_sid') || sessionStorage.getItem('s_user_sid')) {
        newState.appConfig.isLogged = true
      }

      return newState
    case 'app/loading/start':
      newState = _.cloneDeep(state)

      newState.appConfig.openLoading = true

      return newState
    case 'app/loading/stop':
      newState = _.cloneDeep(state)

      newState.appConfig.openLoading = false

      return newState
    default:
      return state
  }
}

export default appReducer