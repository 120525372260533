import Paper from '@mui/material/Paper'
import shadows from '@mui/system/shadows'
import Typography from '@mui/material/Typography'
import GridComponent from '../Grid'
import ButtonComponent from '../Button'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const rootStyle = css({
  padding: '15px',
  margin: '15px',
  minWidth: 100
})

const PaperComponent = (props) => {
  const { item, onChange } = props

  const { title, titleAction, items, action } = item

  return (
    <Paper
      css={rootStyle}
      elevation={3}
    >
      { title ? (
        <Typography variant='h6' sx={{fontWeight: 'bold'}}>
          {title}
        </Typography>
      ) : ''}
      { titleAction ? (
        <ButtonComponent item={titleAction} />
      ) : ''}
      { items ? (
        <GridComponent items={items} onChange={onChange}/>
      ) : ''}
      { action ? (
        <ButtonComponent item={action} />
      ) : ''}
    </Paper>
  )

}

export default PaperComponent