window.appConfig = {
  appPrefix: 'app',
  actions: {
    auth: {
      loginPost: {
        path: '/api/auth/login'
      },
      logoutPost: {
        path: '/api/auth/logout'
      }
    },
    dashboard: {
      dashboardsGet: {
        path: '/api/dashboard'
      }
    },
    instanceRestart: {
      instanceRestartGet: {
        path: '/api/instance/restart'
      }
    },
    instanceCreate: {
      instanceCreateGet: {
        path: '/api/instance/create'
      }
    },
    instanceUpdate: {
      instanceUpdateGet: {
        path: '/api/instance/update'
      }
    },
    instanceView: {
      instanceViewGet: {
        path: '/api/instance/view'
      }
    },
    instanceMove: {
      instanceMoveGet: {
        path: '/api/instance/move'
      }
    },
    instanceRegenerate: {
      instanceRegeneratePost: {
        path: '/api/instance/regenerate'
      }
    },
    instanceDelete: {
      instanceDeleteGet: {
        path: '/api/instance/delete'
      }
    },
    db: {
      dbsGet: {
        path: '/api/db'
      },
      dbGet: {
        path: '/api/db/edit'
      },
      dbSavePost: {
        path: '/api/db/save'
      }
    },
    server: {
      serversGet: {
        path: '/api/server'
      },
      serverGet: {
        path: '/api/server/edit'
      },
      serverSavePost: {
        path: '/api/server/save'
      }
    }
  }
}