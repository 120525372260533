import Typography from '@mui/material/Typography'

const TitleBlock = (props) => {
  const { item } = props

  return (
    <div>
      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
        {item.text}
      </Typography>
    </div>
  )
}

export default TitleBlock