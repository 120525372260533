import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const rootStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, .6)',
  zIndex: 99999
})

const Loading = (props) => {
  const { appConfig } = useSelector(state => state.appReducer)

  if (!appConfig.openLoading) {
    return ''
  }

  return (
    <div css={rootStyle}>
      <CircularProgress/>
    </div>
  )
}

export default Loading