import GridComponent from '../../components/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import md5 from 'md5'

import {
  dashboardActions
} from '../../actions'

const Dashboard = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dashboardActions.dashboardGet(props.pageType, {}))
  }, [props.pageType])

  const { items } = useSelector(state => state.dashboardReducer)

  if (!items) {
    return ''
  }

  return (
    <GridComponent key={ md5(JSON.stringify(items)) } items={items}/>
  )
}

export default Dashboard