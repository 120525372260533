import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client'

import PaperComponent from '../../components/Paper'

import {
  actionFormActions,
  messageActions,
  appActions
} from '../../actions'

const ActionForm = (props) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const match = useParams()

  const [currentSocket, setCurrentSocket] = useState(null)

  const closeSocket = () => {
    if (currentSocket) {
      currentSocket.disconnect()

      currentSocket.close()

      dispatch(actionFormActions.actionFormCleanSocketConfig())
    }
  }

  useEffect(() => {
    const socket = socketIOClient()

    setCurrentSocket(socket)

    dispatch(actionFormActions.actionFormGet(pageType, match))
  }, [])

  useEffect(() => {
    return () => {
      closeSocket()
    }
  }, [currentSocket])

  const { appConfig } = useSelector(state => state.appReducer)

  const { data, goTo, socketConf } = useSelector(state => state.actionFormReducer)

  const { pageType } = props

  const handleChange = (params, field, value, fieldIndex) => {
    dispatch(actionFormActions.actionFormAddValue(value, field.name, fieldIndex))
  }

  useEffect(() => {
    if(goTo) {
      closeSocket()

      navigate('/' + appConfig.appPrefix + goTo)

      dispatch(actionFormActions.actionFormNavigate())
    }
  }, [goTo])

  useEffect(() => {
    if (socketConf && currentSocket) {
      dispatch(appActions.startLoading())

      if (socketConf.error) {
        currentSocket.once(socketConf.error, error => {
          console.log(error)

          dispatch(appActions.stopLoading())

          dispatch(actionFormActions.actionFormCleanSocketConfig())

          dispatch(messageActions.showMessage({
            message: error,
            type: 'error',
            autoHide: true
          }))
        })
      }

      if (socketConf.message) {
        currentSocket.once(socketConf.message, data => {
          console.log(data)

          dispatch(messageActions.showMessage({
            message: data.message,
            type: data.type || 'info',
            autoHide: true
          }))

          if(data.goTo) {
            navigate('/' + appConfig.appPrefix + data.goTo)
          }
        })
      }

      if (socketConf.close) {
        currentSocket.once(socketConf.close, data => {
          console.log(data)

          closeSocket()

          dispatch(appActions.stopLoading())

          dispatch(messageActions.showMessage({
            message: data.message,
            type: 'success',
            autoHide: true
          }))

          if(data.goTo) {
            navigate('/' + appConfig.appPrefix + data.goTo)
          }
        })
      }
    }
  }, [socketConf])

  if (!data) {
    return ''
  }

  if (data.action) {
    if (!data.action.actionType && data.action.action) {
      data.action.onClick = () => {
        dispatch(actionFormActions.actionFormPost(pageType, {}, data.action.action))
      }
    }
  }

  return (
    <div>
      <PaperComponent item={data} onChange={handleChange}/>
    </div>
  )
}

export default ActionForm