import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import shadows from '@mui/system/shadows'
import { NavLink } from 'react-router-dom'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'

import { commonActions } from '../../actions/Common'
import { ACTION_TYPES } from '../../utils/components'

const rootStyle = css({
  padding: '15px'
})

const buttonStyle = css({
  width: '100%'
})

const ButtonComponent = (props) => {
  const dispatch = useDispatch()

  const { item } = props

  const { onClick, action, actionType, text, icon, style = {} } = item

  const { appConfig } = useSelector(state => state.appReducer)

  let actionProps = {}

  if (onClick) {
    actionProps = {
      onClick
    }
  } else {
    switch (actionType) {
      case ACTION_TYPES.navlink:
        actionProps = {
          component: NavLink,
          to: '/' + appConfig.appPrefix + action
        }

        break
      case ACTION_TYPES.ajax:
        actionProps = {
          onClick: () => {
            dispatch(commonActions.callPost({}, {}, action))
          }
        }

        break
      default:
    }
  }

  return (
    <div css={rootStyle}>
      <Button
        variant={style.variant || 'contained'}
        css={style.wrap ? null : buttonStyle}
        startIcon={ icon ? <Icon>{icon}</Icon> : '' }
        size={style.size || 'medium'}
        sx={{
          boxShadow: (style.variant || 'contained') == 'contained' ? 6 : 0,
          background: style.color,
          'paddingTop': style.height || 1,
          'paddingBottom': style.height || 1
        }}
        {...actionProps}
      >
        { text }
      </Button>
    </div>
  )
}

export default ButtonComponent