
import { useEffect, useState } from 'react'
import SelectField from '../../FormField/Select'

const TableSelectFilter = (props) => {
  const { onChange, element } = props

  const [ value, setValue ] = useState()

  const [ options, setOptions ] = useState()

  useEffect(() => {
    const { filter } = element

    const options = Object.entries(element.filter.options).map(option => ({
      value: option[1],
      label: option[0],
    }))

    const tmpValue = options.find(item => {
      return item.value === filter.value
    })

    setValue(tmpValue)

    setOptions(options)
  }, [])

  return (
    <SelectField
      item={{
        options,
        value,
        placeholder: element.filter.placeholder,
        isClearable: true,
        label: element.text
      }}
      onChange={(action, item, newValue) => {
        onChange(element, newValue)

        setValue(newValue)
      }}
    />
  )
}

export default TableSelectFilter