import _ from 'lodash'

const initialState = {
  openMessage: false,
  messageConfig: {}
}

const messageReducer = (
  state = initialState,
  action
) => {
  let newState = null

  switch (action.type) {
    case 'message/show':
      newState = {
        ..._.cloneDeep(state),
        openMessage: true,
        messageConfig: action.obj
      }

      return newState
    case 'message/close':
      if (action.messageId && action.messageId === state.messageId) {
        return state
      }

      newState = {
        ..._.cloneDeep(state),
        openMessage: false
      }

      return newState
    default:
      return state
  }
}

export default messageReducer
