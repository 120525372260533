import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ButtonComponent from '../Button'
import { IconButton, Icon } from '@mui/material'
import { useDispatch } from 'react-redux'

import getTypes from '../../components'
import { isArray } from 'lodash'

import {
  actionFormActions
} from '../../actions'

const createElements = (fields, current, onChange, parentIndex, groupIndex, handleRemoveFields) => {
  const fieldsDiv = fields.map((field, index) => {
    if (current && current.repeatable && isArray(field)) {
      return (
        <Grid container key={index}>
          {
            createElements(field, current, onChange, parentIndex || index, index, handleRemoveFields)
          }
          <IconButton color='error' onClick={() => handleRemoveFields(index, parentIndex)}>
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      )
    }

    const TmpType = getTypes()[field.type] || null

    const name = current && current.name && current.repeatable ? `[${groupIndex}][${field.name}]` : field.name

    if (TmpType) {
      return (
        <Grid key={index} item xs={field.size || 12} p={ field.type.includes('form') ? 1 : 0 }>
          <TmpType item={{
            ...field,
            name: name
          }} onChange={onChange} index={parentIndex || index}/>
        </Grid>
      )
    }

    return ''
  })

  return fieldsDiv
}

const GridComponent = (props) => {
  const {
    items,
    item = {},
    onChange,
    index
  } = props

  const [ fields, setFields ] = useState(items || [item.items])

  const dispatch = useDispatch()

  const handleAddFields = () => {
    const values = [...fields]

    values.push(item.items)

    setFields(values)
  }

  const handleRemoveFields = (index, parentIndex) => {
    const field = fields[index]

    const values = [...fields]

    values.splice(index, 1)

    setFields(values)

    dispatch(actionFormActions.actionFormRemoveValue(field.name, parentIndex))
  }

  if (!fields) {
    return ''
  }

  return (
    <div>
      { item.label ? (
        <Typography>
          {item.label}
        </Typography>
      ) : ''}
      <Grid container spacing={2} p={item.items ? 1 : 3}>
        { createElements(fields, item, onChange, index, null, handleRemoveFields) }
      </Grid>
      { item.repeatable ? (
        <ButtonComponent item={{
          icon: 'add',
          text: 'Aggiungi',
          style: {
            variant: 'outlined',
            size: 'small',
            wrap: true
          },
          onClick: handleAddFields
        }} />
      ) : ''}
    </div>
  )
}

export default GridComponent