import store from '../stores'

export const messageActions = {
  showMessage: (obj) => {
    store.dispatch(messageActions.closeMessage(obj))

    if (obj.messageId) {
      return {
        type: 'message/show',
        obj
      }
    }

    setTimeout(() => {
      store.dispatch(messageActions.showMessageForReal(obj))
    }, 200)

    return {
      type: 'message/show-not-real'
    }
  },
  showMessageForReal: (obj) => ({
    type: 'message/show',
    obj
  }),
  closeMessage: () => {
    return {
      type: 'message/close',
    }
  }
}
